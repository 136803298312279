.step {
    margin-bottom: 40px;
}
.step h2 {
    color: #2c3e50;
}
.step h3 {
    color: #34495e;
}
.step p, .step ul {
    margin-left: 20px;
}